import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import {
  isLocalhost,
  isNetlifyPreview,
  isSynthetics,
} from '@/components/shared/utility/host';
import { IBody } from '@/types/services/lead';
import {
  IDownloadBrochureTracking,
  ILCVModelTracking,
  IMoARActivityTracking,
  IProductCompareTracking,
} from '@/types/tracking/';

import { log } from '../shared/element/debug-bar';
import { IFlutter, useFlutterWebview } from './flutter-webview';

declare const window: any;

let TD_CLIENT = null;

export const useTDPageview = () => {
  const FLUTTER_DATA: IFlutter = useFlutterWebview();

  const trackPageView = () => {
    if (!TD_CLIENT) return;

    let myID = window._myid || '';
    if (FLUTTER_DATA && FLUTTER_DATA.my_id) {
      myID = FLUTTER_DATA.my_id;
    }

    TD_CLIENT.set('$global', { my_id: myID });

    TD_CLIENT.trackPageview('pageviews');
    log(
      `trackPageview: ${TD_CLIENT.client.track.values.td_path()} with my_id: ${
        TD_CLIENT.client.globals.$global.my_id
      }`
    );
  };

  useEffect(() => {
    const init = async () => {
      if (isLocalhost()) return;
      if (isSynthetics()) return;
      if (isNetlifyPreview()) return;

      const Treasure = (await import('td-js-sdk')).default;
      TD_CLIENT = new Treasure({
        database: process.env.NEXT_PUBLIC_TD_DATABASE,
        writeKey: process.env.NEXT_PUBLIC_TD_API_KEY,
        startInSignedMode: true,
        sscDomain: 'isuzulaos.com',
        sscServer: 'ssc.isuzulaos.com',
        useServerSideCookie: true,
        storeConsentByLocalStorage: true,
      });
      window._td = TD_CLIENT;

      TD_CLIENT.set('$global', { td_global_id: 'td_global_id' });
      let myID = window._myid || '';
      if (FLUTTER_DATA && FLUTTER_DATA.my_id) {
        myID = FLUTTER_DATA.my_id;
      }
      TD_CLIENT.set('$global', { my_id: myID });

      TD_CLIENT.set('$global', {
        line_uid_cookie: Cookies.get('_line_uid') || '',
      });
      TD_CLIENT.set('$global', { ga_cookie: Cookies.get('_ga') || '' });

      const successCallback = (serverSideId) => {
        TD_CLIENT.set('$global', { td_ssc_id: serverSideId });
        trackPageView();
      };

      const errorCallback = () => {
        trackPageView();
      };

      TD_CLIENT.fetchServerCookie(successCallback, errorCallback);
    };
    init();
  }, []);

  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', trackPageView);

    return () => {
      router.events.off('routeChangeComplete', trackPageView);
    };
  }, [router]);
};

const removeSpan = (value) => {
  return value.replace('<span>', '').replace('</span>', '');
};
export const trackIsuzuCare = async (params: IBody) => {
  if (isLocalhost()) return;
  if (isSynthetics()) return;
  if (!TD_CLIENT) return;

  const campaignName = removeSpan(params.ctsCampaignName);

  TD_CLIENT.trackEvent('isuzucare_form', {
    campaign_name: campaignName,
    campaign_id: params.ctsCampaignId,
    campaign_version: params.ctsCampaignVersion,
    name: `${params.firstName} ${params.surname}`,
    phone: params.mobile,
    line: params.lineId,
    email: params.email,
    available_time: params.availableTime,
  });
};

export const trackTeletec = async (params: IBody) => {
  if (isLocalhost()) return;
  if (isSynthetics()) return;
  if (!TD_CLIENT) return;

  const campaignName = removeSpan(params.ctsCampaignName);

  TD_CLIENT.trackEvent('teletec_form', {
    campaign_name: campaignName,
    campaign_id: params.ctsCampaignId,
    campaign_version: params.ctsCampaignVersion,
    name: `${params.firstName} ${params.surname}`,
    phone: params.mobile,
    line: params.lineId,
    email: params.email,
    available_time: params.availableTime,
  });
};

export const trackTruck2Hand = async (params: IBody) => {
  if (isLocalhost()) return;
  if (isSynthetics()) return;
  if (!TD_CLIENT) return;

  const campaignName = removeSpan(params.ctsCampaignName);

  TD_CLIENT.trackEvent('truck2hand_form', {
    campaign_name: campaignName,
    campaign_id: params.ctsCampaignId,
    campaign_version: params.ctsCampaignVersion,
    name: `${params.firstName} ${params.surname}`,
    phone: params.mobile,
  });
};

export const trackTILLoyalty = async (params: IBody) => {
  if (isLocalhost()) return;
  if (isSynthetics()) return;
  if (!TD_CLIENT) return;

  const campaignName = removeSpan(params.ctsCampaignName);

  TD_CLIENT.trackEvent('tilloyalty_form', {
    campaign_name: campaignName,
    campaign_id: params.ctsCampaignId,
    campaign_version: params.ctsCampaignVersion,
    name: `${params.firstName} ${params.surname}`,
    phone: params.mobile,
    branchCode: params.branchCode,
    model: params.model,
  });
};

export const trackLCVCompare = async (params: IProductCompareTracking) => {
  if (isLocalhost()) return;
  if (isSynthetics()) return;
  if (!TD_CLIENT) return;

  TD_CLIENT.trackEvent('isuzu_tis_compare_action', {
    model_one: params.model_one || '',
    model_two: params.model_two || '',
    model_three: params.model_three || '',
    sub_model_one: params.sub_model_one || '',
    sub_model_two: params.sub_model_two || '',
    sub_model_three: params.sub_model_three || '',
    color_one_en: params.color_one_en || '',
    color_one_th: params.color_one_th || '',
    color_two_en: params.color_two_en || '',
    color_two_th: params.color_two_th || '',
    color_three_en: params.color_three_en || '',
    color_three_th: params.color_three_th || '',
  });
};

export const trackLCVDownloadBrochure = async (
  params: IDownloadBrochureTracking
) => {
  if (isLocalhost()) return;
  if (isSynthetics()) return;
  if (!TD_CLIENT) return;

  TD_CLIENT.trackEvent('click_brochure', {
    url: params.url,
  });
};

export const trackLCVCalculatorTD = async (params: ILCVModelTracking) => {
  if (isLocalhost()) return;
  if (isSynthetics()) return;
  if (!TD_CLIENT) return;

  TD_CLIENT.trackEvent('calculate_model', {
    model: params.category,
    sub_model: params.drivetrain,
    model_color: params.color,
    deposit_number: params.deposit_price,
    deposit_percentage: params.preferred_price_range,
    interest_rate: params.interest_rate,
    financing_balance: params.financing_balance,
    price: params.price,
    installment_period: params.period,
  });
};

export const trackMoARActivity = async (params: IMoARActivityTracking) => {
  if (isLocalhost()) return;
  if (isSynthetics()) return;
  if (!TD_CLIENT) return;

  TD_CLIENT.trackEvent('moars_activities', {
    season: params.season,
    activityName: params.activityName,
    answer: params.answer,
    reason: params.reason,
    name: params.name,
    mobile: params.mobile,
  });
};

export const trackTISLRegister = async (params: IBody) => {
  if (isLocalhost()) return;
  if (isSynthetics()) return;
  if (!TD_CLIENT) return;

  const campaignName = removeSpan(params.ctsCampaignName);

  TD_CLIENT.trackEvent('register_form', {
    campaign_name: campaignName,
    campaign_id: params.ctsCampaignId,
    campaign_version: params.ctsCampaignVersion,
    name: `${params.firstName} ${params.surname}`,
    phone: params.mobile,
    branch: params.branchName,
    model: params.model,
    remark: params.remark,
  });
};
