import { IWeb } from '@/types/config';

export const siteNameByWeb = (web: IWeb) => {
  switch (web) {
    case 'cv':
      return 'รถบรรทุกอีซูซุประเทศไทย ISUZU TRUCKS Thailand Official';
    case 'lcv':
      return 'ອີຊູຊຸລາວ - Isuzu TIS Laos';
  }
};

export const titleByWeb = (web: IWeb) => {
  switch (web) {
    case 'cv':
      return 'รถบรรทุก - ISUZU TRUCKS Thailand Official';
    case 'lcv':
      return 'ອີຊູຊຸລາວ - Isuzu TIS Laos';
  }
};

export const descriptionByWeb = (web: IWeb) => {
  switch (web) {
    case 'cv':
      return 'ISUZU TRUCKS Thailand Official เจ้าแห่งรถบรรทุก อีซูซุ คิงออฟทรัคส์';
    case 'lcv':
      return 'ອີຊູຊຸລາວ - Isuzu TIS Laos';
  }
};

export const ogImageByWeb = (web: IWeb) => {
  switch (web) {
    case 'cv':
      return 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb94acb3d7bd05f9a/61de8c045ee73041f45e7e9a/OG-0.jpg';
    case 'lcv':
      return 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb8ebe5359f8c9db3/62fb311dea0d864c464abcaf/OG_ISUZU.jpg';
  }
};
